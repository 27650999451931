<template>
  <div style="font-weight: 500">
    <div class="mb-2">
      <span
        style="font-size: 16px;"
        v-if="profile.first_role === 'super-admin'"
      >
        <el-tooltip
          :enterable="false"
          content="編輯 “FAQ”"
          placement="top"
          popper-class="tooltipColor"
        >
          <el-button type="primary" size="mini" @click="editTopic">
            <i class="el-icon-edit"></i> FAQ
          </el-button>
        </el-tooltip>
      </span>
    </div>
    <el-collapse v-model="activeNames" style="text-align:left;">
      <el-collapse-item
        v-for="(faq, index) in faqsData"
        :key="index"
        :name="index"
        v-show="faq.id !== 3"
      >
        <template slot="title">
          <span
            class="collapseTitle"
            v-html="helper.displayI18nText($i18n.locale, faq.question)"
          ></span>
        </template>
        <p v-html="helper.displayI18nText($i18n.locale, faq.explanation)"></p>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  // props "id" 是 router 變數，小寫 & 空白改為 hyphen
  // faqs 是目前選擇的 faqs，如果不是經由 parent 導頁，是重新整理頁面或直接輸入網址進入，此項會變成 undefined
  // 下面 watch 即是處理 undefinded 時的方法
  props: ["id"],
  data() {
    return {
      activeNames: [],
      faqsData: []
    };
  },
  computed: {
    ...mapGetters("faq", ["getFaqs", "getCurrentLabel", "getCurrentTopic"]),
    ...mapState("user", ["profile"]),
    helper() {
      return helper;
    }
  },
  watch: {
    // 重新整理時也能由網址的 id 得到目前的 faqs
    // 因 parent router 與 child router created & mounted hooks 是同步進行，
    // dispatch 在 parent router(Faq.vue)，child router 直接取 getFaqs 的值會取到 undefinded
    // 因為異步處理資料還沒 assign 進去
    // 又不想 call API 兩次，因此使用 watch 監聽 getFaqs 的變化，如果資料進來則會更新 views
    getFaqs(newVal) {
      if (!this.faqsData) {
        // 找出目前 Faq 的 Topic 與 Label
        let currentTopic = this.getTopicById(newVal);
        let currentLabel = this.getLebelById(newVal, currentTopic.faq_label_id);
        this.faqsData = currentTopic.faqs;

        this.$store.dispatch("faq/saveCurrentLabel", currentLabel);
        this.$store.dispatch("faq/saveCurrentTopic", currentTopic);
      }
    }
  },
  created() {
    this.faqsData = this.getCurrentTopic.faqs;
  },
  destroyed() {
    this.activeNames = [];
  },
  methods: {
    handleRouterName(str) {
      return str.replace(/\s+/g, "-").toLowerCase();
    },
    // 尋找 id 所對應的 topic object
    getTopicById(newVal) {
      let currentTopic = {};
      newVal.faq_labels.forEach(x => {
        x.topics.forEach(y => {
          if (this.handleRouterName(y.title.en) === this.id) {
            currentTopic = y;
          }
        });
      });

      return currentTopic;
    },
    // 用 topic 裡面的 id 尋找 label
    getLebelById(newVal, id) {
      let currentLabel = {};
      newVal.faq_labels.forEach(x => {
        if (x.id === id) {
          currentLabel = x;
        }
      });
      return currentLabel;
    },
    editTopic() {
      this.$router.push({
        name: "EditFaqTopic"
      });
    }
  }
};
</script>

<style scoped>
.tableFont {
  font-size: 12px;
}
a {
  color: #42a16a;
}
.collapseTitle {
  font-weight: bold;
  font-size: 16px;
  color: #42a16a;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .el-collapse-item__header {
  line-height: 200%;
  height: auto;
}
::v-deep .el-collapse-item__header:hover {
  opacity: 0.6;
}
</style>
